<template lang="html">

  <section class="encabezado">
    <div class="container">
      <div>
        <h1>¡Bienvenidos a las ZONA DSP de R-SAMi!</h1>

        <h3>Optimiza la gestión de tus recursos y genera mayores beneficios en tu DSP.</h3>
      <h2>Enfócate de una vez por todas en aumentar los beneficios de tu negocio y delega en R-SAMi la organización y
        gestión de tus operaciones y recursos con solo un click.</h2>
      <a href="https://r-sami.com/es/demo" target="_blank">¡QUIERO HACERLO!</a>
      </div>
      <div>
        
      </div>
    </div>
  </section>
  <section class="beneficios">
    <div class="container">
      <div>
        <h2>Si pudieras tener más beneficios y menos trabajo diario, ¿aceptarías el reto?</h2>

      </div>
      <div>
      <p>Nosotros estuvimos donde estás tú ahora: días largos organizando infinidad de recursos… pero tus beneficios
        mensuales no reflejan el trabajo duro.</p>
      <p>Hemos creado R-SAMi para que todos los DSPs de España puedan administrar y asignar a diario todos sus recursos:
        drivers, teléfonos móviles, rutas y vehículos.</p>
      <p>¿Y lo mejor de todo?</p>
      <p>Que lo puedes hacer en un solo click (¡literalmente!)</p>
      </div>
    </div>
    
  </section>

  <section class="como-trabajamos ">
    <div class="container">
      <div>
      <h2>¿Cómo trabaja R-SAMi?</h2>
      <h3>Unifica tus recursos:</h3>
      <p>Dile adiós a las decenas de planillas que manejas día a día. R-SAMi unifica y conecta todos tus activos dentro de una sola plataforma.</p>
      <p>Sabrás qué tienes, cuándo lo tienes y dónde lo tienes.</p>
      <h3>Planifica en un click:</h3>
      <p>Cuando recibas las rutas, podrás introducirlas directamente en R-SAMi para que nuestro sistema, de manera automática, asigne los drivers que ese día están legalmente autorizados para trabajar.</p>
      <h3>Dile adiós a los vacíos legales:</h3>
      <p>R-SAMi tiene en cuenta la legislación laboral vigente y el convenio colectivo. El sistema identificará las horas trabajadas, extras y restantes que cada conductor tiene en un mes.<br>En base a eso, asignará (o no) los recursos para que tu DSP esté SIEMPRE dentro de la ley.</p>
      <h3> Gestiona accidentes en tiempo real:</h3>
      <p>¿Te cuesta hacer seguimiento de los accidentes?
        <br>R-SAMi te provee con un sistema en tiempo real para que los conductores, en el momento del siniestro, puedan cargar toda la información necesaria para su gestión: fotos, documentos, lugar, día y hora.

        <br>¡Incluso podrán enviar un correo de manera automática a la compañía aseguradora!
        <br>Ya no tendrás sorpresas al final del día.
      
    </p>
      <h3>Comienza y finaliza el día sin estrés.</h3>
      <p>

        Tus dispatchers te lo agradecerán.<br>
        La integración 360 de R-SAMi te permite hacer el “check in” y el “check out” diario de todos tus recursos de manera ágil, sencilla y controlada.
<br>Ahorras tiempo…y dinero.
      </p>
    </div>
    <div></div>
    </div>

  </section>

  <section class="btn-demo">
    <a href="https://r-sami.com/es/demo" target="_blank">RESERVA AQUÍ TU DEMO GRATUITA <img src="/img/flecha-blanca.svg"></a>
  </section>

  <section class="dsp">
   <div class="container">
    <h2>¿Te pasa lo mismo que a los demás DSPs de España?</h2>
    <div class="subtitle">
      <p>Tu día a día como DSP</p>
      <p>Tu día a día como DSP con R-SAMi</p>
    </div>

    <div class="mejoras" >
      <div class="lightgray" >
        <div class="titulo">
          <p>Tu trabajo se incrementa, pero tus beneficios disminuyen</p>
          
          

        </div>
        <div class="imagen">
          <img src="/img/arrow.svg" class="pc">
          <img src="/img/arrow_down.svg" class="mv">

        </div>
      </div>
      <div class="lightblue">
        <p>El problema es que no tienes una visión global de tu negocio y sus resultados.</p>
        <p> En R-SAMi podrás determinar día a día tus costes de operación y optimizar recursos para poder aumentar tu margen de ganancias…¡y tus beneficios!</p>
      </div>
    </div>



    <div class="mejoras" >
      <div class="lightgray">
        <div>
          <p>Emprendiste para mejorar tu vída y tener más libertad…y de eso, todavía nada.</p>
          
          

        </div>
        <div class="imagen">
          <img src="/img/arrow.svg" class="pc">
          <img src="/img/arrow_down.svg" class="mv">

        </div>
      </div>
      <div class="lightblue">
        <p>R-SAMi es el camino que necesitas tomar para conseguir esa libertad que tanto buscabas cuando emprendiste
          como DSP.</p>
        <p>¡Súmate a nuestra comunidad de DSPs y siente esa libertad de emprender!</p>
      </div>
    </div>





    <div class="mejoras" >
      <div class="lightgray">
        <div>
          <p>Si tienes una auditoría te pasas la semana juntando información.</p>
            
          
          

        </div>
        <div class="imagen">
          <img src="/img/arrow.svg" class="pc">
          <img src="/img/arrow_down.svg" class="mv">

        </div>
      </div>
      <div class="lightblue">
        <p>R-SAMi te provee informes y cuadros de mando en tiempo real, así como también históricos.</p>
        <p>Tendrás toda la información que necesitas al alcance de tus manos. Todos los días. A toda hora. En tiempo real.</p>
      </div>
    </div>

    <div class="mejoras" >
      <div class="lightgray">
        <div>
          <p>Gran parte de tu trabajo (y el de tu equipo) es sumergirse en decenas de planillas que no están conectadas.<br>Un error en la información o su falta de actualización, pone en riesgo tu empresa y tus beneficios.</p>
          
          

        </div>
        <div class="imagen">
          <img src="/img/arrow.svg" class="pc">
          <img src="/img/arrow_down.svg" class="mv">

        </div>
      </div>
      <div class="lightblue">
        <p>R-SAMi almacena y cruza TODAS LAS ÁREAS de tu negocio para que tengas la información ordenada y sistematizada.</p>
        <p>Dile adiós a los datos dispersos en decenas de plantillas…y dile “¡Hola!” a la información consolidada y 100% confiable.</p>
      </div>
    </div>


    <div class="mejoras" >
      <div class="lightgray">
        <div>
          <p>Hay días que estás a punto de rendirte</p>
          
          

        </div>
        <div class="imagen">
          <img src="/img/arrow.svg" class="pc">
          <img src="/img/arrow_down.svg" class="mv">

        </div>
      </div>
      <div class="lightblue">
        <p>¡Hemos estado en tu situación! Hay días que ser DSP es un verdadero desafío.</p>
        <p>Pero con el constante apoyo de nuestro soporte PREMIUM incluido en todos nuestros planes, tendrás
          el apoyo que necesitas para seguir adelante.</p>
      </div>
    </div>



    <div class="mejoras ultimo" >
      <div class="lightgray">
        <div>
          <p>Compensar horas extra “a devolver” a los miembros de tu equipo es un trabajo titánico.
            Tienes que comparar horas trabajadas, horas restantes del mes…horas, horas y horas.
            Hay días que parece una tarea imposible retener a tus drivers para que crezcan dentro de tu DSP.</p>
          

        </div>
        <div class="imagen">
          <img src="/img/arrow.svg" class="pc">
          <img src="/img/arrow_down.svg" class="mv">

        </div>
      </div>
      <div class="lightblue">
        <p>En R-SAMi puedes tener un reporte de horas trabajadas y a compensar, en un solo click.</p>
        <p>Nuestro sistema tiene integrado el convenio colectivo vigente para que en cuestión de segundos puedas saber
          qué Drivers tienen horas de más o de menos.</p>
      </div>
    </div>

   </div>
  </section>

  <section class="demo-gratuita">
    <div class="container">
      <h2>¿Quieres saber cómo lograr escalar tus beneficios y reducir tus horas de trabajo?</h2>
    <p>Reserva hoy mismo una demostración gratuita y sin compromiso con nuestros expertos en DSPs España.</p>
    <a href="https://r-sami.com/es/demo" target="_blank">¡QUIERO MI DEMO GRATUITA!</a>

    </div>
  </section>

</template>

<script lang="js">

export default {
  name: 'home',
  props: [],
  mounted() {

  },
  data() {
    return {
      showMenuResponsive: true,


    }
  },
  methods: {


  },
  computed: {

  }
}


</script>

<style scoped lang="scss">


.home {}
</style>